<template>
    <div>
        <p :class ="textColor + ' text-h6 font-weight-bold pt-10'">
            以下参考（{{ title }}）
        </p>
        <v-simple-table :style="'border:1px solid ' + tableBorderColor" class="tbl uq_cfTable">
            <template v-slot:default >
                <tbody class="text-center">
                    <tr :class ="textColor + ' text--darken-1'">
                        <td rowspan="2" style="width:8.3333%;"></td>
                        <td rowspan="2" style="width:8.3333%; border-left:1px solid #ccc;">住戸の番号</td>
                        <td rowspan="2" style="width:8.3333%;">住戸の存する階</td>
                        <td rowspan="2" style="width:8.3333%;">
                            床面積の合計
                            <span class="success--text d-block">[m<sup>2</sup>]</span>
                        </td>
                        <td colspan="2" style="border-left:1px solid #ccc; width:25%;">外皮性能</td>
                        <td colspan="5" style="border-left:1px solid #ccc; width:41.6666%;">一次エネルギー消費性能</td>
                    </tr>
                    <tr :class ="textColor + ' text--darken-1'">
                        <td style="border-left:1px solid #ccc;">
                            外皮平均<br/>熱貫流率<br/>(U<sub>A</sub>)
                            <span class="grey--text d-block">[W/m<sup>2</sup>K]</span>
                        </td>
                        <td>
                            冷房期平均<br/>日射熱取得率<br/>(&#951;<sub>AC</sub>)
                            <span class="grey--text d-block">[-]</span>
                        </td>
                        <td style="border-left:1px solid #ccc;">
                            設計一次<br/>エネルギー<br/>消費量
                            <span class="grey--text d-block">[GJ]</span>
                        </td>
                        <td>
                            基準一次<br/>エネルギー<br/>消費量
                            <span class="grey--text d-block">[GJ]</span>
                        </td>
                        <td>
                            設計一次<br/>エネルギー<br/>消費量<br/>(その他除く)
                            <span class="grey--text d-block">[GJ]</span>
                        </td>
                        <td>
                            基準一次<br/>エネルギー<br/>消費量<br/>(その他除く)
                            <span class="grey--text d-block">[GJ]</span>
                        </td>
                        <td>
                            BEI
                        </td>
                    </tr>
                    <tr>
                        <td :class ="textColor + ' text-left font-weight-bold' ">
                            BEI<span class="text-no-wrap">代表住戸</span>
                        </td>
                        <td style="border-left:1px solid #ccc;">{{ beiInfo.beiDuRepHousehold.householdNum }}</td>
                        <td>{{ beiInfo.beiDuRepHousehold.floor }}</td>
                        <td>{{ beiInfo.beiDuRepHousehold.totalArea }}</td>
                        <td style="border-left:1px solid #ccc;">{{ beiInfo.beiDuRepHousehold.ua }}</td>
                        <td>{{ beiInfo.beiDuRepHousehold.etaAc }}</td>
                        <td style="border-left:1px solid #ccc;">{{ beiInfo.beiDuRepHousehold.eTDu }}</td>
                        <td>{{ beiInfo.beiDuRepHousehold.eStDu }}</td>
                        <td>{{ beiInfo.beiDuRepHousehold.eTDuWithoutEM }}</td>
                        <td>{{ beiInfo.beiDuRepHousehold.eStDuWithoutESm }}</td>
                        <td>{{ beiInfo.beiDuRepHousehold.beiDu }}</td>
                    </tr>
                    <tr>
                        <td :class ="textColor + ' text-left font-weight-bold'">
                            BEI<span class="text-no-wrap">最大住戸</span>
                        </td>
                        <td style="border-left:1px solid #ccc;">{{ beiInfo.beiDuMaxHousehold.householdNum }}</td>
                        <td>{{ beiInfo.beiDuMaxHousehold.floor }}</td>
                        <td>{{ beiInfo.beiDuMaxHousehold.totalArea }}</td>
                        <td style="border-left:1px solid #ccc;">{{ beiInfo.beiDuMaxHousehold.ua }}</td>
                        <td>{{ beiInfo.beiDuMaxHousehold.etaAc }}</td>
                        <td style="border-left:1px solid #ccc;">{{ beiInfo.beiDuMaxHousehold.eTDu }}</td>
                        <td>{{ beiInfo.beiDuMaxHousehold.eStDu }}</td>
                        <td>{{ beiInfo.beiDuMaxHousehold.eTDuWithoutEM }}</td>
                        <td>{{ beiInfo.beiDuMaxHousehold.eStDuWithoutESm }}</td>
                        <td>{{ beiInfo.beiDuMaxHousehold.beiDu }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <p class="pt-2">
            BEI代表住戸:BEIが全住戸の中間に位置する住戸(建築物の住戸の数が偶数の場合はBEIの小さい(省エネ性能の良い)方)<br/>
            BEI代表住戸が複数ある場合はU<sub>A</sub>が中間値 → &#951;<sub>AC</sub> が中間値 → 床面積が最大値 → 住戸番号が小さい(一番上の行)の順に判断
        </p>
        <p>
            BEI最大住戸:BEIが全住戸の中で最大の住戸<br/>
            BEI最大住戸が複数ある場合はU<sub>A</sub>が最大値 → &#951;<sub>AC</sub> が最大値 → 床面積が最大値 → 住戸番号が小さい(一番上の行)の順に判断
        </p>
    </div>
</template>
<script>
export default ({
    props: ["title", "beiInfo", "textColor", "tableBorderColor"]
})
</script>
