<template>
    <div>
        <p class="text-h6 font-weight-bold teal--text text--darken-1">
            基本情報
        </p>
        <v-simple-table style="border:1px solid #4ACAA0">
            <template v-slot:default >
                <tbody>
                    <tr>
                        <td class="teal--text text--darken-1" style=" width:16.6666%">建物名称</td>
                        <td colspan="5" class="black--text" style="border-left:1px solid #ccc;">{{ buildingInfo.buildingName }}</td>
                    </tr>
                    <tr>
                        <td class="teal--text text--darken-1">地域の区分</td>
                        <td style="border-left:1px solid #ccc; width: 33.3333%;">{{ buildingInfo.chiki }}地域</td>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc; width:16.6666%;">住棟全体の住戸数</td>
                        <td colspan="3" style="border-left:1px solid #ccc; width: 33.3333%;">{{buildingInfo.householdTotalNum}}戸</td>
                    </tr>
                    <tr>
                        <td class="teal--text text--darken-1">入力方法</td>
                        <td style="border-left:1px solid #ccc;">{{ buildingInfo.evaluationMethod }}</td>
                        <td class="teal--text text--darken-1" style="border-left:1px solid #ccc;">一次エネの評価方法</td>
                        <td colspan="3" style="border-left:1px solid #ccc;">{{ buildingInfo.energyStandard }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <!-- 建築物エネルギー消費性能基準 -->
        <sub-result
             title="建築物エネルギー消費性能基準"
             standard-type = "general"
             v-bind:aggregate-result="aggregateResults.general">
        </sub-result>

        <!-- 建築物エネルギー消費性能誘導基準 -->
        <div v-if="aggregateResults.induction" class="mt-16 bl_yudokijun" style="border-top: 2px solid #00897b">
            <sub-result
                title="建築物エネルギー消費性能誘導基準"
                standard-type = "induction"
                v-bind:aggregate-result="aggregateResults.induction">
            </sub-result>
        </div>

        <v-row class="pl-5 pr-5">
            <v-btn
                @click="backPreviousPage"
                rounded-lg
                color="#A0A0A0"
                width="150"
                height="60"
                class="mt-15 white--text font-weight-bold text-h6"
            >
                <v-icon large>mdi-chevron-left</v-icon>
                <v-spacer></v-spacer>
                    戻る
                <v-spacer></v-spacer>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                to="pdf-generate"
                color="#025F59"
                width="200"
                height="60"
                class="mt-15 ml-5 white--text font-weight-bold text-h6"
            >
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                    PDF生成
                <v-spacer></v-spacer>
                <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SubResult from './SubResult.vue'

export default {
    components: {
        SubResult
    },
    computed: {
        ...mapGetters('basicInfo', {
            basicInfo: 'getBasicInfo',
            commonAreaType: 'getCommonAreaType'
            }),
        ...mapGetters('result', {
            buildingInfo: 'getBuildingInfo',
            aggregateResults: 'getAggregateResults'
        })
    },
    methods : {
        //共用部の有無で計算結果から戻る先を変える
        backPreviousPage () {
            if (this.commonAreaType) {
                this.$router.push('common-area-upload')
            } else {
                this.$router.push('household-upload')
            }
        }
    }
}
</script>
<style scoped>
.tbl td {
  padding: 0 8px !important;
}
</style>